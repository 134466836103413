import React, { useState } from 'react'
import { FormGroup, TextField } from '@mui/material'
import { FilterAction } from 'components/Filter/FilterAction'

export function FilterText({
  label,
  value,
  onFilter,
}: {
  label: string
  value: string | undefined
  onFilter: (value: string | undefined) => void
}) {
  const [text, setText] = useState(value ?? '')

  return (
    <FormGroup sx={{ p: 2 }}>
      <TextField
        sx={{ my: 1 }}
        size="small"
        label={label}
        value={text}
        onChange={(event) => setText(event.target.value)}
      />
      <FilterAction
        onReset={() => {
          setText('')
        }}
        onSubmit={() => {
          if (text === '') {
            onFilter(undefined)
            return
          }
          onFilter(text)
        }}
      />
    </FormGroup>
  )
}
