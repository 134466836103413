// @generated by protobuf-ts 2.8.1 with parameter long_type_number,generate_dependencies
// @generated from protobuf file "google/protobuf/any.proto" (package "google.protobuf", syntax proto3)
// tslint:disable
//
// Protocol Buffers - Google's data interchange format
// Copyright 2008 Google Inc.  All rights reserved.
// https://developers.google.com/protocol-buffers/
//
// Redistribution and use in source and binary forms, with or without
// modification, are permitted provided that the following conditions are
// met:
//
//     * Redistributions of source code must retain the above copyright
// notice, this list of conditions and the following disclaimer.
//     * Redistributions in binary form must reproduce the above
// copyright notice, this list of conditions and the following disclaimer
// in the documentation and/or other materials provided with the
// distribution.
//     * Neither the name of Google Inc. nor the names of its
// contributors may be used to endorse or promote products derived from
// this software without specific prior written permission.
//
// THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
// "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
// LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
// A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT
// OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
// SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
// LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE,
// DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY
// THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
// (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE
// OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { isJsonObject } from "@protobuf-ts/runtime";
import { typeofJsonValue } from "@protobuf-ts/runtime";
import type { JsonValue } from "@protobuf-ts/runtime";
import { jsonWriteOptions } from "@protobuf-ts/runtime";
import type { JsonReadOptions } from "@protobuf-ts/runtime";
import type { JsonWriteOptions } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IMessageType } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * `Any` contains an arbitrary serialized protocol buffer message along with a
 * URL that describes the type of the serialized message.
 *
 * Protobuf library provides support to pack/unpack Any values in the form
 * of utility functions or additional generated methods of the Any type.
 *
 * Example 1: Pack and unpack a message in C++.
 *
 *     Foo foo = ...;
 *     Any any;
 *     any.PackFrom(foo);
 *     ...
 *     if (any.UnpackTo(&foo)) {
 *       ...
 *     }
 *
 * Example 2: Pack and unpack a message in Java.
 *
 *     Foo foo = ...;
 *     Any any = Any.pack(foo);
 *     ...
 *     if (any.is(Foo.class)) {
 *       foo = any.unpack(Foo.class);
 *     }
 *     // or ...
 *     if (any.isSameTypeAs(Foo.getDefaultInstance())) {
 *       foo = any.unpack(Foo.getDefaultInstance());
 *     }
 *
 *  Example 3: Pack and unpack a message in Python.
 *
 *     foo = Foo(...)
 *     any = Any()
 *     any.Pack(foo)
 *     ...
 *     if any.Is(Foo.DESCRIPTOR):
 *       any.Unpack(foo)
 *       ...
 *
 *  Example 4: Pack and unpack a message in Go
 *
 *      foo := &pb.Foo{...}
 *      any, err := anypb.New(foo)
 *      if err != nil {
 *        ...
 *      }
 *      ...
 *      foo := &pb.Foo{}
 *      if err := any.UnmarshalTo(foo); err != nil {
 *        ...
 *      }
 *
 * The pack methods provided by protobuf library will by default use
 * 'type.googleapis.com/full.type.name' as the type URL and the unpack
 * methods only use the fully qualified type name after the last '/'
 * in the type URL, for example "foo.bar.com/x/y.z" will yield type
 * name "y.z".
 *
 * JSON
 * ====
 * The JSON representation of an `Any` value uses the regular
 * representation of the deserialized, embedded message, with an
 * additional field `@type` which contains the type URL. Example:
 *
 *     package google.profile;
 *     message Person {
 *       string first_name = 1;
 *       string last_name = 2;
 *     }
 *
 *     {
 *       "@type": "type.googleapis.com/google.profile.Person",
 *       "firstName": <string>,
 *       "lastName": <string>
 *     }
 *
 * If the embedded message type is well-known and has a custom JSON
 * representation, that representation will be embedded adding a field
 * `value` which holds the custom JSON in addition to the `@type`
 * field. Example (for message [google.protobuf.Duration][]):
 *
 *     {
 *       "@type": "type.googleapis.com/google.protobuf.Duration",
 *       "value": "1.212s"
 *     }
 *
 *
 * @generated from protobuf message google.protobuf.Any
 */
export interface Any {
  /**
   * A URL/resource name that uniquely identifies the type of the serialized
   * protocol buffer message. This string must contain at least
   * one "/" character. The last segment of the URL's path must represent
   * the fully qualified name of the type (as in
   * `path/google.protobuf.Duration`). The name should be in a canonical form
   * (e.g., leading "." is not accepted).
   *
   * In practice, teams usually precompile into the binary all types that they
   * expect it to use in the context of Any. However, for URLs which use the
   * scheme `http`, `https`, or no scheme, one can optionally set up a type
   * server that maps type URLs to message definitions as follows:
   *
   * * If no scheme is provided, `https` is assumed.
   * * An HTTP GET on the URL must yield a [google.protobuf.Type][]
   *   value in binary format, or produce an error.
   * * Applications are allowed to cache lookup results based on the
   *   URL, or have them precompiled into a binary to avoid any
   *   lookup. Therefore, binary compatibility needs to be preserved
   *   on changes to types. (Use versioned type names to manage
   *   breaking changes.)
   *
   * Note: this functionality is not currently available in the official
   * protobuf release, and it is not used for type URLs beginning with
   * type.googleapis.com. As of May 2023, there are no widely used type server
   * implementations and no plans to implement one.
   *
   * Schemes other than `http`, `https` (or the empty scheme) might be
   * used with implementation specific semantics.
   *
   *
   * @generated from protobuf field: string type_url = 1;
   */
  typeUrl: string;
  /**
   * Must be a valid serialized protocol buffer of the above specified type.
   *
   * @generated from protobuf field: bytes value = 2;
   */
  value: Uint8Array;
}
// @generated message type with reflection information, may provide speed optimized methods
class Any$Type extends MessageType<Any> {
  constructor() {
    super("google.protobuf.Any", [
      { no: 1, name: "type_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: "value", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
    ]);
  }
  /**
   * Pack the message into a new `Any`.
   *
   * Uses 'type.googleapis.com/full.type.name' as the type URL.
   */
  pack<T extends object>(message: T, type: IMessageType<T>): Any {
    return {
      typeUrl: this.typeNameToUrl(type.typeName),
      value: type.toBinary(message),
    };
  }
  /**
   * Unpack the message from the `Any`.
   */
  unpack<T extends object>(
    any: Any,
    type: IMessageType<T>,
    options?: Partial<BinaryReadOptions>,
  ): T {
    if (!this.contains(any, type))
      throw new Error(
        "Cannot unpack google.protobuf.Any with typeUrl '" +
          any.typeUrl +
          "' as " +
          type.typeName +
          ".",
      );
    return type.fromBinary(any.value, options);
  }
  /**
   * Does the given `Any` contain a packed message of the given type?
   */
  contains(any: Any, type: IMessageType<any> | string): boolean {
    if (!any.typeUrl.length) return false;
    let wants = typeof type == "string" ? type : type.typeName;
    let has = this.typeUrlToName(any.typeUrl);
    return wants === has;
  }
  /**
   * Convert the message to canonical JSON value.
   *
   * You have to provide the `typeRegistry` option so that the
   * packed message can be converted to JSON.
   *
   * The `typeRegistry` option is also required to read
   * `google.protobuf.Any` from JSON format.
   */
  internalJsonWrite(any: Any, options: JsonWriteOptions): JsonValue {
    if (any.typeUrl === "") return {};
    let typeName = this.typeUrlToName(any.typeUrl);
    let opt = jsonWriteOptions(options);
    let type = opt.typeRegistry?.find((t) => t.typeName === typeName);
    if (!type)
      throw new globalThis.Error(
        "Unable to convert google.protobuf.Any with typeUrl '" +
          any.typeUrl +
          "' to JSON. The specified type " +
          typeName +
          " is not available in the type registry.",
      );
    let value = type.fromBinary(any.value, { readUnknownField: false });
    let json = type.internalJsonWrite(value, opt);
    if (typeName.startsWith("google.protobuf.") || !isJsonObject(json))
      json = { value: json };
    json["@type"] = any.typeUrl;
    return json;
  }
  internalJsonRead(
    json: JsonValue,
    options: JsonReadOptions,
    target?: Any,
  ): Any {
    if (!isJsonObject(json))
      throw new globalThis.Error(
        "Unable to parse google.protobuf.Any from JSON " +
          typeofJsonValue(json) +
          ".",
      );
    if (typeof json["@type"] != "string" || json["@type"] == "")
      return this.create();
    let typeName = this.typeUrlToName(json["@type"]);
    let type = options?.typeRegistry?.find((t) => t.typeName == typeName);
    if (!type)
      throw new globalThis.Error(
        "Unable to parse google.protobuf.Any from JSON. The specified type " +
          typeName +
          " is not available in the type registry.",
      );
    let value;
    if (typeName.startsWith("google.protobuf.") && json.hasOwnProperty("value"))
      value = type.fromJson(json["value"], options);
    else {
      let copy = Object.assign({}, json);
      delete copy["@type"];
      value = type.fromJson(copy, options);
    }
    if (target === undefined) target = this.create();
    target.typeUrl = json["@type"];
    target.value = type.toBinary(value);
    return target;
  }
  typeNameToUrl(name: string): string {
    if (!name.length) throw new Error("invalid type name: " + name);
    return "type.googleapis.com/" + name;
  }
  typeUrlToName(url: string): string {
    if (!url.length) throw new Error("invalid type url: " + url);
    let slash = url.lastIndexOf("/");
    let name = slash > 0 ? url.substring(slash + 1) : url;
    if (!name.length) throw new Error("invalid type url: " + url);
    return name;
  }
  create(value?: PartialMessage<Any>): Any {
    const message = { typeUrl: "", value: new Uint8Array(0) };
    globalThis.Object.defineProperty(message, MESSAGE_TYPE, {
      enumerable: false,
      value: this,
    });
    if (value !== undefined) reflectionMergePartial<Any>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Any,
  ): Any {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string type_url */ 1:
          message.typeUrl = reader.string();
          break;
        case /* bytes value */ 2:
          message.value = reader.bytes();
          break;
        default:
          let u = options.readUnknownField;
          if (u === "throw")
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: Any,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string type_url = 1; */
    if (message.typeUrl !== "")
      writer.tag(1, WireType.LengthDelimited).string(message.typeUrl);
    /* bytes value = 2; */
    if (message.value.length)
      writer.tag(2, WireType.LengthDelimited).bytes(message.value);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message google.protobuf.Any
 */
export const Any = new Any$Type();
