import React, { useState } from 'react'
import { View } from 'react-native'
import { FilterState, useMedia } from 'models/Medium'
import { MediumList } from 'components/Medium/List'
import PageHeader from 'components/Shared/PageHeader'
import { MediumResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_resource'
import { usePagination } from 'components/Shared/DataGrid'

export default function Affiliate() {
  const pagination = usePagination()
  const [filters, setFilters] = useState<FilterState>({
    statuses: MediumResource_Status.UNDER_REVIEW,
    name: undefined,
    url: undefined,
  })

  const { media, mutate, isLoading } = useMedia({
    per: pagination.perPage,
    page: pagination.page,
    status:
      filters.statuses !== undefined ? { value: filters.statuses } : undefined,
    name: filters.name !== undefined ? { value: filters.name } : undefined,
    url: filters.url !== undefined ? { value: filters.url } : undefined,
  })

  return (
    <View>
      <PageHeader title="承認待ちメディア">
        <MediumList
          media={media}
          onMediumUpdated={mutate}
          filters={filters}
          setFilters={setFilters}
          disableFilters={{ statuses: true }}
          pagination={pagination}
          isLoading={isLoading}
        />
      </PageHeader>
    </View>
  )
}
