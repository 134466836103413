export const Colors = {
  primary: '#ab935a',
  primary10: 'rgba(171,147,90,0.1)',
  primary16: 'rgba(171,147,90,0.16)',
  primary32: 'rgba(171,147,90,0.32)',
  primary40: 'rgba(171,147,90,0.4)',
  primary90: 'rgba(171,147,90,0.9)',
  primaryBg: 'rgba(171,147,90,0.06)',
  disabledPrimary: 'rgba(171,147,90,0.1)',
  accent: '#009688',
  accentBg: 'rgba(0,150,136,0.06)',
  accent10: 'rgba(0,150,136,0.1)',
  accent20: 'rgba(0,150,136,0.2)',
  accent40: 'rgba(0,150,136,0.4)',
  accent50: 'rgba(0,150,136,0.5)',
  black: '#444444',
  black6: 'rgba(68, 68, 68, 0.06)',
  black10: 'rgba(68,68,68,0.1)',
  black16: 'rgba(68,68,68,0.16)',
  black20: 'rgba(68,68,68,0.2)',
  black40: 'rgba(68,68,68,0.4)',
  black50: 'rgba(68, 68, 68, 0.5)',
  black60: 'rgba(68, 68, 68, 0.6)',
  black80: 'rgba(68,68,68,0.8)',
  blue: 'rgba(44,103,192,1)',
  blueBg: 'rgba(44,103,192,0.1)',
  gray: '#666666',
  success: '#009688',
  caution: '#e84733',
  review: '#FC8007',
  cautionBg: 'rgba(232,71,51,0.06)',
  caution90: 'rgba(232,71,51,0.9)',
  caution20: 'rgba(232,71,51,0.2)',
  caution40: 'rgba(232,71,51,0.4)',
  caution60: 'rgba(232,71,51,0.6)',
  secondaryBlack: 'rgba(68,68,68,0.6)',
  disabledBlack: 'rgba(68,68,68,0.4)',
  bgBlack: 'rgba(68,68,68,0.06)',
  bgLightBlack: 'rgba(68,68,68,0.01)',
  border: 'rgba(0,0,0,0.16)',
  darkOverlay: '#000',
  darkOverlay10: 'rgba(0,0,0,0.1)',
  darkOverlay20: 'rgba(0,0,0,0.2)',
  textDisabled: 'rgba(68,68,68,0.2)',
  field: 'rgb(245,245,245)',
  sliderUnselected: '#E1E1E1',
  fieldDisabled: 'rgba(245,245,245,.2)',
  separator: '#d8d8d8',
  white: '#ffffff',
  orange: '#ed6400',
  orangeBg: 'rgba(252,128,7,0.1)',
  professional: 'linear-gradient(135deg, #FB6C72 0%, #FFB100 100%)',
  morning: '#FF7138',
  lunch: '#F2B005',
  dinner: '#001377',
} as const
