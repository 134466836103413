import { assertNever } from 'modules/assert'
import { createRpcService, rpcOptions } from 'modules/rpc'
import useSWR, { swrKey } from 'modules/swr'
import { useToken } from './Auth'

import { MediumResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_resource'
import {
  GetRequest,
  ListRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_service'
import { MediumClient } from '@hello-ai/proto/src/gen/auto_reserve/admin/medium/medium_service.client'

export const mediumService = createRpcService(MediumClient)
export const mediumClient = mediumService.client

export function getStatusName(value: MediumResource_Status) {
  switch (value) {
    case MediumResource_Status.UNDER_REVIEW:
      return '審査中'
    case MediumResource_Status.CERTIFIED:
      return '承認'
    case MediumResource_Status.NOT_CERTIFIED:
      return '非承認'
    default: {
      assertNever(value)
    }
  }
}

export function isCertified(status: MediumResource_Status) {
  return status === MediumResource_Status.CERTIFIED
}

export function useMedia(params: ListRequest) {
  const token = useToken()

  const { data, mutate, isLoading, error } = useSWR(
    swrKey(token, `Medium/List/`, params),
    () => mediumClient.list(params, rpcOptions({ token })).response
  )

  return {
    media: data?.media,
    mutate,
    isLoading,
    error,
  }
}

export function useMedium(params: GetRequest) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    swrKey(token, `Medium/Get/${params.id}`, params),
    () => mediumClient.get(params, rpcOptions({ token })).response,
    { keepPreviousData: true }
  )

  return {
    medium: data,
    mutate,
    error,
  }
}

export const STATUSES = [
  MediumResource_Status.UNDER_REVIEW,
  MediumResource_Status.CERTIFIED,
  MediumResource_Status.NOT_CERTIFIED,
].map((status) => ({ value: status, text: getStatusName(status) }))

export type FilterState = {
  statuses: MediumResource_Status | undefined
  name: string | undefined
  url: string | undefined
}
