import React, { ReactNode } from 'react'
import { Grid, Typography } from '@mui/material'
import { SystemStyleObject, Theme } from '@mui/system'

function Descriptions({
  children,
  title,
}: {
  children: ReactNode
  title?: string
}) {
  return (
    <>
      {title !== undefined && (
        <Typography variant="h4" sx={{ marginTop: '32px' }}>
          {title}
        </Typography>
      )}
      <Grid
        container
        columns={{ sm: 1, md: 2, lg: 3 }}
        rowSpacing={2}
        columnSpacing={1}
        sx={title != null ? { marginTop: '8px' } : { marginTop: '0px' }}
      >
        {children}
      </Grid>
    </>
  )
}

function DescriptionItem({
  label,
  children,
  xs,
  sx,
}: {
  label: string
  children: ReactNode
  xs?: number
  sx?: SystemStyleObject<Theme>
}) {
  return (
    <Grid item xs={xs || 1} sx={sx}>
      {label}: {children}
    </Grid>
  )
}

export { Descriptions, DescriptionItem }
