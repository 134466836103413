import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
} from '@mui/material'
import Breadcrumb from 'components/Shared/Breadcrumb'
import { DescriptionItem, Descriptions } from 'components/Shared/Descriptions'
import PageHeader from 'components/Shared/PageHeader'
import Config from '@hello-ai/for_r_app/src/modules/config'
import dayjs from 'dayjs'
import { useToken } from 'models/Auth'
import {
  postRestaurantOwnershipVerificationRequest,
  useRestaurantOwnershipVerificationRequest,
} from 'models/Owner'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { z } from 'zod'

const schema = z.object({
  reason: z.string().min(1, '1文字以上入力してください'),
})
type DeclinePromptSchema = z.infer<typeof schema>

function DeclinePromptDialog({
  onSubmit,
}: {
  onSubmit: (data: DeclinePromptSchema) => Promise<void>
}) {
  const form = useForm<DeclinePromptSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      reason: '',
    },
  })

  const { isValid, isDirty } = form.formState

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>否認理由を入力してください</DialogTitle>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogContent sx={{ p: 4 }}>
          <TextField
            label="否認理由"
            multiline
            rows={4}
            fullWidth
            style={{
              padding: 8,
            }}
            {...form.register('reason')}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" disabled={!isValid || !isDirty}>
            否認する
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

function Content({ id }: { id: string }) {
  const { data } = useRestaurantOwnershipVerificationRequest(id)
  const token = useToken()
  const [isOpenDeclinePrompt, setIsOpenDeclinePrompt] = React.useState(false)
  const navigate = useNavigate()

  const onPressSubmitDecline = useCallback(() => {
    setIsOpenDeclinePrompt(true)
  }, [])
  const onPressSubmitApprove = useCallback(async () => {
    if (token == null) return
    if (!confirm(`承認します。本当によろしいですか？`)) return
    const { error } = await postRestaurantOwnershipVerificationRequest(
      token,
      id,
      'approve'
    )
    if (error != null) return
    navigate(-1)
  }, [token, id, navigate])

  if (data == null)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )

  const disableActions = data.status !== 'submitted'

  return (
    <Box>
      <Breadcrumb
        routes={[
          {
            path: '/restaurant_ownership_verification_requests',
            breadcrumbName: '許可証一覧',
          },
          {
            path: `restaurant_ownership_verification_requests/${id}`,
            breadcrumbName: data.id,
          },
        ]}
      />
      <PageHeader
        title={`営業許可証詳細`}
        sx={{ mt: 4 }}
        extra={[
          <Button
            key="decline"
            variant="outlined"
            color="error"
            onClick={onPressSubmitDecline}
            disabled={disableActions}
          >
            否認
          </Button>,
          <Button
            key="approve"
            variant="outlined"
            onClick={onPressSubmitApprove}
            disabled={disableActions}
          >
            承認
          </Button>,
        ]}
      >
        <Descriptions>
          <DescriptionItem label="オーナーID">
            <Link
              href={`https://admin.autoreserve.com/owners/${data.owner.id}`}
            >
              {data.owner.id}
            </Link>
          </DescriptionItem>
          <DescriptionItem label="オーナー名">
            {data.owner.name}
          </DescriptionItem>
        </Descriptions>
        <Descriptions>
          <DescriptionItem label="レストランID">
            <Link
              href={`https://admin.autoreserve.com/restaurants/${data.restaurant.id}`}
            >
              {data.restaurant.id}
            </Link>
          </DescriptionItem>
          <DescriptionItem label="レストラン名">
            {data.restaurant.name}
          </DescriptionItem>
        </Descriptions>
        <Descriptions>
          <DescriptionItem label="ARページ" xs={3}>
            <Link href={data.ar_url} target="_blank" rel="noreferrer">
              {data.ar_url}
            </Link>
          </DescriptionItem>
        </Descriptions>
        <Descriptions title="営業許可証">
          {data.restaurant_ownership_verification_request_business_licenses.map(
            (license, index) => (
              <Box key={index}>
                {license.file.url.endsWith('.pdf') ? (
                  <Link
                    href={license.file.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    ファイルを開いて確認する
                  </Link>
                ) : (
                  <div>
                    <img
                      src={license.file.url}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </div>
                )}
              </Box>
            )
          )}
        </Descriptions>
      </PageHeader>
      {isOpenDeclinePrompt && (
        <DeclinePromptDialog
          onSubmit={async (data) => {
            if (token == null) return
            const { error } = await postRestaurantOwnershipVerificationRequest(
              token,
              id,
              'decline',
              data
            )
            if (error != null) return
            setIsOpenDeclinePrompt(false)
            navigate(-1)
          }}
        />
      )}
    </Box>
  )
}

export default function RestaurantOwnershipVerificationRequest() {
  const { restaurantOwnershipVerificationRequestId } = useParams<{
    restaurantOwnershipVerificationRequestId: string
  }>()

  if (restaurantOwnershipVerificationRequestId == null) {
    return <div>不正なIDです</div>
  }

  return <Content id={restaurantOwnershipVerificationRequestId} />
}
