import { swrKey } from '@hello-ai/ar_shared/src/modules/swr'
import { OwnerResource_PayoutIntervalType } from '@hello-ai/proto/src/gen/auto_reserve/admin/owner/owner_resource'
import { OwnerClient } from '@hello-ai/proto/src/gen/auto_reserve/admin/owner/owner_service.client'
import { assertNever } from 'modules/assert'
import axios, { setHeader, wrapResponse } from 'modules/axios'
import { createRpcService } from 'modules/rpc'
import useSWR, { fetcher, mutate } from 'modules/swr'
import { onError, useToken } from './Auth'

export const ownerService = createRpcService(OwnerClient)
export const ownerClient = ownerService.client

export function getPayoutIntervalTypeName(
  value: OwnerResource_PayoutIntervalType
) {
  switch (value) {
    case OwnerResource_PayoutIntervalType.MONTH:
      return '毎月'
    case OwnerResource_PayoutIntervalType.TWO_WEEKS:
      return '隔週'
    default: {
      assertNever(value)
    }
  }
}

type RestaurantOwnershipVerificationStatus =
  | 'approved'
  | 'declined'
  | 'submitted'

interface RestaurantOwnershipVerificationRequest {
  id: string
  status: RestaurantOwnershipVerificationStatus
  restaurant: {
    id: number
    name: string
  }
  owner: {
    id: number
    name: string
  }
  has_business_license: false
  has_verification_code: false
  created_at: string
  ar_url: string
}

export function useRestaurantOwnershipVerificationRequests() {
  const token = useToken()

  return useSWR<
    {
      restaurant_ownership_verification_requests: RestaurantOwnershipVerificationRequest[]
    },
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, '/restaurant_ownership_verification_requests'), fetcher)
}

interface RequestOwnershipVerificationRequestDetail
  extends RestaurantOwnershipVerificationRequest {
  restaurant_ownership_verification_request_business_licenses: {
    file: {
      url: string
    }
  }[]
}

export function useRestaurantOwnershipVerificationRequest(id: string) {
  const token = useToken()

  return useSWR<
    RequestOwnershipVerificationRequestDetail,
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/restaurant_ownership_verification_requests/${id}`), fetcher)
}

export type PostRestaurantOwnershipVerificationAction = 'approve' | 'decline'

export async function postRestaurantOwnershipVerificationRequest(
  token: string,
  id: string,
  action: PostRestaurantOwnershipVerificationAction,
  body?: {
    reason: string
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post(
      `/restaurant_ownership_verification_requests/${id}/${action}`,
      body
    )
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/restaurant_ownership_verification_requests/${id}`))
  }

  if (error != null) {
    onError(error)
  }
  return {
    error,
  }
}
