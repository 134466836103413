import React, { useState } from 'react'
import { View } from 'react-native'
import { useMedia, FilterState } from 'models/Medium'
import { MediumList } from 'components/Medium/List'
import PageHeader from 'components/Shared/PageHeader'
import { usePagination } from 'components/Shared/DataGrid'

export default function AffiliateMedia() {
  const pagination = usePagination()
  const [filters, setFilters] = useState<FilterState>({
    statuses: undefined,
    name: undefined,
    url: undefined,
  })

  const { media, mutate, isLoading } = useMedia({
    page: pagination.page,
    per: pagination.perPage,
    status:
      filters.statuses !== undefined ? { value: filters.statuses } : undefined,
    name: filters.name !== undefined ? { value: filters.name } : undefined,
    url: filters.url !== undefined ? { value: filters.url } : undefined,
  })

  return (
    <View>
      <PageHeader title="メディア一覧">
        <MediumList
          media={media}
          onMediumUpdated={mutate}
          filters={filters}
          setFilters={setFilters}
          pagination={pagination}
          isLoading={isLoading}
        />
      </PageHeader>
    </View>
  )
}
